<template>
  <div>
    <!-- Page Header -->
    <section class="bg-dark pt-5 pb-4">
      <div class="container py-5">
        <h1 class="text-light pt-1 pt-md-3 mb-4">Settings</h1>
      </div>
    </section>
    <!-- / Page Header -->

    <!-- Body -->
    <section class="position-relative bg-white rounded-xxl-4 zindex-5" style="margin-top: -30px;">
      <div class="container pt-4 pb-5 mb-md-4">
        <!-- Breadcrumb -->
        <breadcrumb :crumbs="crumbs" class="pb-4 my-2"></breadcrumb>
        <!-- / Breadcrumb -->

        <!-- Is Loading -->
        <div v-if="isLoading" class="text-center">
          <spinner></spinner>
        </div>
        <!-- / Is Loading -->

        <!-- Loaded -->
        <div v-if="!isLoading && company">

          <div class="row">
            <div class="col-lg-3">
              <h2 class="h4">Job Quotes</h2>
            </div>
            <div class="col-lg-9">
              <div class="border rounded-3 p-3" id="auth-info">

                <div>
                  <editable-field
                      property="default_post_job_acceptance_message"
                      name="Default Post Job Acceptance Message"
                      :initial-value="company.default_post_job_acceptance_message"
                      endpoint="company"
                      type="textarea"
                      @updated="fetch"
                  ></editable-field>
                </div>

              </div>
            </div>
          </div>

        </div>
        <!-- / Loaded -->
      </div>
    </section>
    <!-- / Body -->
  </div>
</template>

<script>
import Breadcrumb from '../components/Breadcrumb.vue'
import DarkHeaderBackground from "../components/DarkHeaderBackground.vue"
import EditableField from "../components/Display/EditableField";
import company from "../api/company";
import Spinner from "../components/Spinner";

export default {
  components: {DarkHeaderBackground, Breadcrumb, EditableField, Spinner},
  data() {
    return {
      crumbs: [
        {
          href: '/',
          label: 'Home'
        },
        {
          href: '#',
          label: 'Settings',
          active: true
        }
      ],
      isLoading: false,
      company: null
    }
  },
  created() {
    this.fetch();
  },
  methods: {
    fetch() {
      let vm = this;
      vm.isLoading = true;
      company.fetch().then(r => {
        vm.isLoading = false;
        vm.company = r.data;
      }).catch(e => {
        console.log(e);
        vm.isLoading = false;
      });
    }
  }
}
</script>

<style>
</style>